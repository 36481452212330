import ajaxRequest from "@/utils/ajaxRequest";
export const APIGetProductList = (params) => {
  return ajaxRequest.postJson(`/api/v1/product/list`, params);
};

export const APIGetRecommendProductList = (params) => {
  return ajaxRequest.get(`/api/v1/product/getRecommendByProductId`, params);
};

// 获取收藏列表
export const APIGetLikeProductList = (params) => {
  return ajaxRequest.postJson(`/api/v1/favoriteItems/list`, params);
};

// 用户信息
export const APIGetUserInfo = () => {
  return ajaxRequest.get(`/api/v1/user/get`, {});
};
// 获取达人GMV
export const APIGetGMV = () => {
  return ajaxRequest.get(`/api/v1/user/getGMV`, {});
};

// 用户GMV
export const APIGetUserGMV = () => {
  return ajaxRequest.get(`/api/v1/user/getGMV`, {});
};

// {productId:string} // 获取商品详情
export const APIGetProductInfo = (params) => {
  return ajaxRequest.get(`/api/v1/product/getProduct`, params);
};

// {uid:string, itemId:string} // 增加收藏
export const APIAddFavoriteItems = (params) => {
  return ajaxRequest.get(`/api/v1/favoriteItems/add`, params);
};

// {collectId:string} // 删除收藏
export const APIDeleteFavoriteItems = (params) => {
  return ajaxRequest.get(`/api/v1/favoriteItems/remove`, params);
};

// {collectId:string} // 检查是否收藏
export const APICheckCollect = (params) => {
  return ajaxRequest.get(`/api/v1/favoriteItems/checkCollect`, params);
};

// 获取商品一级类目
export const APIGetCategoryFirst = () => {
  return ajaxRequest.get(`/api/v1/category/first`, {});
};

// 根据一级获取二级类目
export const APIGetCategorySecond = (params) => {
  return ajaxRequest.get(`/api/v1/category/sub`, params);
};

// 获取推荐商品
export const APIGetRecommendList = () => {
  return ajaxRequest.post(`/api/v1/recommend/list`, {});
};

// 一键加橱链接生成
export const APICreatLinkByPid = (params) => {
  return ajaxRequest.postJson(`/api/v1/favoriteItems/creatLink`, params);
};

// 获取一键加橱链接
export const APIGetLinkByBatchId = (batchId) => {
  return ajaxRequest.get(`/api/v1/favoriteItems/queryLink`, { batchId });
};


export const APIGetLinkByPid = (productId) => {
  return ajaxRequest.get(`/api/v1/product/getNewLink`, { productId });
};

// 登录
export const APILogin = () => {
  return ajaxRequest.get(`/api/v2/tiktok/login`, {});
};

// 橱窗商品列表
export const APIGetMyShowCase = (params) => {
  return ajaxRequest.get(`/api/v1/user/getShowcases`, params);
};

// 获取授权信息
export const APIGetCheckV2Permissions = () => {
  return ajaxRequest.get(`/api/v2/tiktok/checkV2permissions`);
};

// h5加厨统计次数
export const APIH5ClickStatistics = (params) => {
  return ajaxRequest.get(`/api/v1/product/h5Click`, params);
};


// 获取达人佣金以及分布数据
// { selectType: 1 最近一周 2最近一月 }
export const APIGetCommisson = (params) => {
  return ajaxRequest.get(`/api/v1/reportData/getCommisson`, params);
};

// 获取达人anyStarrGMV
export const APIGetAnyStarrGMV = (params) => {
  return ajaxRequest.get(`/api/v1/reportData/getGMV`, params);
};

// 取消申佣红点
export const APIFinishRedPoint = (params) => {
  return ajaxRequest.get(`/api/v1/applyCommission/finishRedPoint`, params);
};

// 获取申佣商品列表
export const APIGetCommissonList = (params) => {
  return ajaxRequest.get(`/api/v1/applyCommission/list`, params);
};

export const APIGetGuideFinish = (params) => {
  return ajaxRequest.get(`/api/v1/user/guideFinish`, params);
};

// 异常示例
export const APIErrorFetch = (params) => {
  return ajaxRequest.get(`/api/v1/category/mock`, params);
};


// 问卷调查
export const APIFeedback = (params) => {
  return ajaxRequest.postJson(`/api/v1/feedback/create`, params);
};

// cap邀约
export const APICapInvitation = (params) => {
  return ajaxRequest.get(`/api/v1/feedback/capInvitation`, params);
};
// 刷新cap邀约
export const APIRefreshCapStatus = () => {
  return ajaxRequest.get(`/api/v1/feedback/refreshCapStatus`);
};

// 线下申样
export const APIRequestSampleCreate = (params) => {
  return ajaxRequest.postJson(`/api/v1/requestSample/create`, params);
};
