'use client';
import dynamic from "next/dynamic";
import { Modal } from "antd";
import { useMemo } from "react";

const Icon = dynamic(() => import("@iconify/react").then((mod) => mod.Icon), { ssr: false });

const AppCommonModal = ({ show, title, onCancel, children, className, ...rest }) => {

  const renderHeader = useMemo(() => {
    return <div className={"flex items-center justify-between relative"}>
      <div className={"text-[#222222] text-[16px] font-bold"}>{title}</div>
      <div className={"p-1 hover:bg-[#00000010] rounded trans-all absolute right-0"}>
        <Icon icon='line-md:close' onClick={onCancel} className={"w-[20px] cursor-pointer h-[20px] text-[#A7A7A7]"} />
      </div>
    </div>;
  }, [title]);

  return <Modal open={show}
    closeIcon={null}
    width={"max-content"}
    centered footer={null} onCancel={(e) => {
      e.stopPropagation();
      onCancel();
    }}
    className={`flex items-center app-common-modal ${className}`}
    {...rest}
  >
    {
      renderHeader
    }
    {
      children
    }
  </Modal>;
};

export default AppCommonModal;
