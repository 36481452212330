import React, { useEffect, useState } from 'react';
import styles from "./index.module.scss";
import SizeBox from "@/components/SizeBox";
import host from "@/utils/host";
import { Modal } from "antd";
import { generateRandomStringWithTimestamp } from "@/utils/utils";
import AppCommonModal from "@/components/AppCommonModal";

const LoginModal = ({ open, mobile, onCancel }) => {
  useEffect(() => {
    if (open) {
      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复页面滚动
      document.body.style.overflow = '';
    }
    // 在组件卸载时恢复页面滚动
    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);


  const [state, setState] = useState(generateRandomStringWithTimestamp());

  const toLogin = (e) => {
    localStorage.setItem("userTiktokState", state);
  };

  // return <Modal centered footer={null} open={open} onCancel={(e) => {
  //   e.stopPropagation();
  //   onCancel();
  // }}>
  // </Modal>;
  return <AppCommonModal show={open} onCancel={onCancel}>
    <div className={`${styles.login_modal} ${mobile && styles.mobileModal}`}>
      <div className={"flex items-center"} >
        <div className='logo-container !pl-0'>
          {mobile ? <img className='logo !w-[100px] !h-[32px] object-contain' alt='logo' src={"https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anystarr_icon.png"} />
            : <img className='logo !w-[160px] !h-[50px] object-contain' alt='logo' src={"https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anystarr_icon.png"} />
          }
        </div>
      </div>
      <div>
        <div className={`${styles.login_title} text-color5 mt-[25px] font-700 font_noto text-[24px]`}>Login</div>
        <div className={styles.title_desc}>
          <div className={"flex items-start"}>
            <div className={"h-[20px] flex items-center shrink-0"}>
              <div className={"w-1 h-1 bg-color2 mr-1.5 rounded-full shrink-0"}></div>
            </div>
            <div className={"text-color2 font_noto text-sm leading-1.5"}>
              Log in with your TikTok account to earn <span>more commissions</span>!
            </div>
          </div>
          <div className={"flex items-start"}>
            <div className={"h-[20px] flex items-center shrink-0"}>
              <div className={"w-1 h-1 bg-[#222] mr-1 rounded-full"}></div>
            </div>
            <div className={"text-color2 font_noto text-sm leading-1.5"}>
              Please <span>select all</span> authorized content so that we can help you improve your TikTok e-commerce performance.
            </div>
          </div>
        </div>
      </div>
      {mobile ? <SizeBox h={32}></SizeBox> : <SizeBox h={26}></SizeBox>}
      <a href={`${host}/api/v1/tiktok/oauth?state=${state}`} onClick={toLogin}>
        <div className={`link_wallet ${mobile ? '!h-[36px]' : '!h-[48px]'} w-[360px] mx-auto !text-sm hover:opacity-80 trans-all`}>
          Log in with your TikTok account
        </div>
      </a>
      {mobile ? <div className={"bg-[#EDEDED] h-[1px] w-full mt-[30px] mb-[15px]"} ></div> : <div className={"bg-[#EDEDED] h-[1px] w-full mt-[50px] mb-[15px]"} ></div>}
      <div className={`${mobile ? styles.helpMobile : styles.help_wrap}`}>
        <a className={"mr-6 !text-color4 hover:!text-primary trans-all"} href={"/Terms_and_Conditions_anyStarr.html"} target={"_blank"}>Terms and Conditions {" "} </a>
        <a href={"/privacy_policy.html"} className={"!text-color4 hover:!text-primary trans-all"} target={"_blank"}> {" "} Privacy Policy </a>
      </div>
    </div>
  </AppCommonModal>;
};

export default LoginModal;
