import PropTypes from 'prop-types';
import Header from './Header';
import { BackTop } from "antd";
import { useRouter } from "next/router";
import ToHomePageLink from "@/components/ToHomePageIcon";
import InterfaceErrorModal from "@/components/InterfaceErrorModal";
import { contactIcon, questionIcon } from "@/config/config";
import QuestionModal from "@/components/QuestionModal";
import React, { useEffect, useState } from "react";
import { isMobile } from "@/utils/action";
import LoginModal from "@/components/LoginModal";
import { useSelector } from "react-redux";
import { FeedbackProvider, useFeedback } from "@/context/feedback";
import gaTracker from "@/ga";
import useLogin from "@/hooks/useLogin";
import {Icon} from "@iconify/react";
import styles from "@/pages/home-mobile/index.module.scss";
import Footer from "@/components/Footer";

const hideHeader = ["/influencers-2", "/home", "/search"];

const showFeedbackPath = ["/", "/helpCenter"];

const ContactEmail = () => {
  const { feedbackShow, setFeedbackShow } = useFeedback();
  const [openConnectModal, setOpenConnectModal] = useState(false);
  const userInfo = useSelector(state => state.home.userInfo.userInfo);
  const [mobile, setMobile] = useState(false);
  const { loginModalShow } = useLogin();

  const router = useRouter();
  useEffect(() => {
    setMobile(isMobile());
  }, []);

  useEffect(() => {
    if (loginModalShow) {
      setOpenConnectModal(loginModalShow);
    }
  }, [loginModalShow]);

  if (mobile) return <></>;

  if (!showFeedbackPath.includes(router.pathname)) return <></>;

  const openEmail = () => {
    gaTracker.trackEvent("home_email", true);
    const email = 'contact@anystarr.com';
    const subject = 'To anystarr';
    const body = '';

    // 使用mailto协议打开默认的电子邮件客户端
    // 打开邮件客户端
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };

  return <div>
    <LoginModal open={openConnectModal} mobile={mobile} onCancel={() => setOpenConnectModal(false)}></LoginModal>
    <QuestionModal show={feedbackShow} onCancel={() => setFeedbackShow(false)}></QuestionModal>
    <div onClick={(e) => {
      gaTracker.trackEvent("home_feedback_submit");
      if (!(userInfo?.displayName || userInfo?.avatarUrl)) {
        setOpenConnectModal(true);
        return;
      }
      setFeedbackShow(!feedbackShow);
      e.stopPropagation();
    }} className={"docu_Box hover:opacity-50 cursor-pointer"}>
      <Icon icon='material-symbols:chat-paste-go-rounded' className={"text-white w-[30px] h-[30px]"} />
    </div>
    <div onClick={openEmail} className={"mail_Box hover:opacity-50 cursor-pointer !bottom-[144px] !right-[30px]"}>
      <Icon icon='mdi:email-edit' className={"text-white w-[30px] h-[30px]"} />
    </div>
  </div>;
};



function Layout({ children }) {
  return (
    <FeedbackProvider>
      <RealLayout>{children}</RealLayout>
    </FeedbackProvider>
  );
}


function RealLayout({ children }) {
  const router = useRouter();
  const { feedbackShow, setFeedbackShow } = useFeedback();
  return <div onClick={() => setFeedbackShow(false)} style={{
    overflow: "hidden"
  }} className={"overflow"}>
    <style jsx>{`
        .content-container {
          padding-top: 72px;
        }
      `}</style>

    {
      !hideHeader.includes(router.pathname) && <Header />
    }
    <div className='content-container'>
      {children}
    </div>
    <Footer></Footer>
    {/* {
      router.pathname === "/" && <div className={"app-footer"}>
        <div className={"footer-gray"}>
          <div className={"footer-g-content"}>
            <a href={"/Terms_and_Conditions_anyStarr.html"} className={"footer-link-2 link-item"}>
              Terms and Conditions
            </a>
            <a href={"/privacy_policy.html"} className={"footer-link-2 link-item"}>
              Privacy Policy
            </a>
            <a href={"/helpCenter"} className={"footer-link-2 link-item"}>
              Help center
            </a>
          </div>
        </div>
      </div>
    } */}
    <BackTop style={{
      right: "30px"
    }}></BackTop>


    <ToHomePageLink></ToHomePageLink>

    <InterfaceErrorModal></InterfaceErrorModal>

    <ContactEmail></ContactEmail>

  </div>;
}

export default Layout;
Layout.propTypes = {
  children: PropTypes.any
};

Layout.defaultProps = {
  children: null
};
