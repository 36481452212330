
export const LikeIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/liked_icon.png";
export const NoLikeIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/like_icon.png";
export const RecommendIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/recommend_icon.png";
export const ArrowDownIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/down-new-icon.png";
export const CheckedIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/checked_icon.png";
export const NoCheckedIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/no_check_icon.png";
export const ClearIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/clear_icon.png";
export const SearchIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/search_icon.png";
export const HistoryIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/history_icon.png";
export const EmptyIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/empty_icon.png";
export const RightIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/right_icon.png";
export const Step1Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/step_1.png";
export const Step2Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/step_2.png";
export const Step3Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/step_3.png";
export const Step4Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/step_4.png";
export const Info1Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/info_1.png";
export const Info2Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/info_2.png";
export const Info3Icon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/info_3.png";
export const BioIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/bio_icon.png";
export const CategoryIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/category_icon.png";
export const GmvIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/gmv_icon.png";
export const LocationIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/location_icon.png";
export const SoldIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/sold_icon.png";
export const VideoGmvIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/video_gmv_icon.png";
export const ShowcaseIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/showcase_icon.png";
export const StatusIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/status_icon.png";
export const LoadingGIF = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/loading.gif";
export const FailIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/fail_icon.png";
export const TablePre = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/table_pre.png";
export const TableNext = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/table_next.png";
export const QuestionIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/question_icon.png";

export const liveGmvIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/live_gmv.png";
export const ecomVideoIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/ecom_video.png";
export const ecLiveShareIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/ec_live_share.png";
export const ecAvgVideoCommentIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/ec_avg_video_comment.png";
export const eLiveIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/e_live.png";
export const brandCountIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/brand_c_count.png";
export const avgEcVideoShareIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/avg_ec_video_share.png";
export const avgEcVLikeIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/avg_ec_v_like.png";
export const avgEcLiveLikeIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/avg_ec_live_like.png";
export const avgEcCommentIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/avg_ec_comment.png";
export const avgComRateIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/avg_com_rate.png";
export const avgLiveViewIcon = "https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/avg_e_c_live_view.png";
export const lineMdClose = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/line-md_close.png';
export const ToHomePageIcon = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/to_home.png';
export const anlay_top_icon_1 = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anlay_top_icon_1.png';
export const anlay_top_icon_2 = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anlay_top_icon_2.png';
export const anlay_top_icon_3 = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anlay_top_icon_3.png';
export const anlay_top_icon_4 = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anlay_top_icon_4.png';
export const primary_down_icon = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/primary_down_icon.png';
export const anay_icon = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anay_icon.png';
export const pre_btn = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/pre_btn.png';
export const readyIcon = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/ready-icon.png';
export const questionIcon = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/question-icon.png';
export const contactIcon = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/contact-icon.png';
export const InfoIcon3 = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/Info_icon_3.png';
export const InfoIcon4 = 'https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/Info_icon_2.png';
