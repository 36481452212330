import Image from "next/image";
import {useEffect, useState} from "react";
import {isMobile} from "@/utils/action";
import gaTracker from "@/ga";
import Link from "next/link";


const Footer = () =>{
  const [mobile, setMobile] = useState();

  useEffect(()=>{
    setMobile(isMobile());
  }, []);

  const openEmail = () => {
    gaTracker.trackEvent("home_email", true);
    const email = 'contact@anystarr.com';
    const subject = 'To anystarr';
    const body = '';

    // 使用mailto协议打开默认的电子邮件客户端
    // 打开邮件客户端
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };

  if (mobile) return <></>;
  return <div className={"bg-[#F3F4F6] w-full"}>
    <div className={"max-w-[1200px] flex flex-row mx-auto py-5"}>
      <div className={"flex-1 mr-[100px]"}>
        <Image width={145} height={44} src={"/images/anystarr-footer-icon.svg"} alt={""}></Image>
        <div className={"text-[#222222] text-[20px] mt-[20px] font-bold"}>About Us</div>
        <div className={"text-[14px] font-bold mt-3"}>anyStarr: Your One-Stop TikTok E-Commerce Platform</div>
        <div className={"mt-3 text-[14px] font-300 leading-[20px]"}>
          As an official TikTok affiliate partner, anyStarr empowers creators to maximize their earnings with higher commissions, an extensive selection of high-quality products, and dedicated support for long-term success. Join anyStarr today and turn your TikTok influence into real revenue!
        </div>
      </div>
      <div className={"flex-2 flex"}>
        <div>
          <div className={"text-color7 cursor-pointer text-[20px] font-bold mb-3"}>Guides</div>
          <Link href={"/helpCenter?key=2.1"} target={"_blank"}><div className={"text-color7 cursor-pointer text-[14px] mb-3 hover:text-primary trans-all"}>Accounts</div></Link>
          <Link href={"/helpCenter?key=3.1"} target={"_blank"}><div className={"text-color7 cursor-pointer text-[14px] mb-3 hover:text-primary trans-all"}>Samples</div></Link>
          <Link href={"/helpCenter?key=4.1"} target={"_blank"}>
            <div className={"text-color7 cursor-pointer text-[14px] mb-3 hover:text-primary trans-all"}>Products</div>
          </Link>
          <Link href={"/helpCenter?key=5.1"} target={"_blank"}>
            <div className={"text-color7 cursor-pointer text-[14px] mb-3 hover:text-primary trans-all"}>Commissions</div>
          </Link>
          <Link href={"/helpCenter?key=6.1"} target={"_blank"}>
            <div className={"text-color7 cursor-pointer text-[14px] mb-3 hover:text-primary trans-all"}>Logistics</div>
          </Link>
        </div>
        <div className={"ml-[100px]"}>
          <div className={"text-color7 cursor-pointer text-[20px] font-bold mb-3"}>Contact Us</div>
          <div className={"flex items-center"}>
            <div onClick={openEmail} className={"flex items-center w-[200px] cursor-pointer"}>
              <Image width={28} height={28} className={"mr-3"} src={"/images/footer/email-icon.svg"} alt={""}></Image>
              <div className={"text-[14px] text-[#162047C2] hover:text-primary trans-all"}>E-Mail</div>
            </div>
            <a href={"https://www.tiktok.com/@anystarr.us"} target={"_blank"}>
              <div className={"flex items-center w-[200px]"}>
                <Image width={28} height={28} className={"mr-3"} src={"/images/footer/tiktok-icon.svg"} alt={""}></Image>
                <div className={"text-[14px] text-[#162047C2] hover:text-primary trans-all"}>TikTok</div>
              </div>
            </a>
          </div>
          <div className={"flex items-center mt-4"}>
            <Link href={"http://wa.me/6580842554"} target={"_blank"}>
              <div className={"flex items-center w-[200px]"}>
                <Image width={28} height={28} className={"mr-3"} src={"/images/footer/whatsapp-b-icon.svg"} alt={""}></Image>
                <div className={"text-[14px] text-[#162047C2] hover:text-primary trans-all"}>WhatsApp Business</div>
              </div>
            </Link>
            <Link href={"https://whatsapp.com/channel/0029VaiQ3QI2kNFuXs5zp91o"} target={"_blank"}>
              <div className={"flex items-center w-[200px]"}>
                <Image width={28} height={28} className={"mr-3"} src={"/images/footer/whats-app-icon.svg"} alt={""}></Image>
                <div className={"text-[14px] text-[#162047C2] hover:text-primary trans-all"}>WhatsApp Channel</div>
              </div>
            </Link>
          </div>
          <div className={"flex items-center gap-3 mt-[10px]"}>
            <Image width={120} height={160} src={"/images/footer/contact-tiktok-icon.svg"} alt={""}></Image>
            <Image width={120} height={160} src={"/images/footer/contact-whatsapp-b-icon.svg"} alt={""}></Image>
            <Image  width={120} height={160} src={"/images/footer/contact-whats-channel-icon.svg"} alt={""}></Image>
          </div>
        </div>
      </div>
    </div>
    <div className={"bg-color7 w-full mt-[30px] h-[43px]"}>
      <div className={"max-w-[1200px] h-full mx-auto text-[12px] flex text-white items-center justify-between"}>
        <div>abComo ecommerce pte ltd.2021 All rights reserved</div>
        <div className={"flex items-center gap-[20px]"}>
          <Link href={"/Terms_and_Conditions_anyStarr.html"} target={"_blank"}>
            <div className={"hover:text-primary trans-all"}>Terms and Conditions</div>
          </Link>
          <Link href={"/privacy_policy.html"} target={"_blank"}><div className={"hover:text-primary trans-all"}>Privacy Policy</div></Link>
        </div>
      </div>
    </div>
  </div>;
};

export default Footer;
